let dataEffects = {
    1: {
        img: "burning_flames",
        eng: "Burning Flames",
        schema: 13,
        pol: "palące płomienie",
        sch: "熊熊烈焰",
        fre: "Flammes brûlantes",
        bra: "Chamas ardentes",
        hun: "Lobogó lángok",
        swe: "Brinnande Lågor"
    },
    2: {
        img: "circling_heart",
        eng: "Circling Heart",
        schema: 19,
        pol: "wirujące serce",
        sch: "旋转之心",
        fre: "Coeur tournant",
        bra: "Coração circulante",
        hun: "Keringő szív",
        swe: "Cirklande Hjärta"
    },
    3: {
        img: "circling_peace_sign",
        eng: "Circling Peace Sign",
        schema: 18,
        pol: "wirujący znak pokoju",
        sch: "旋转和平标志",
        fre: "Symbole Peace tournant",
        bra: "Símbolo de paz circulante",
        hun: "Keringő béke-jel",
        swe: "Cirklande Fredstecken"
    },
    4: {
        img: "circling_tf_logo",
        eng: "Circling TF Logo",
        schema: 11,
        pol: "wirujące logo TF",
        sch: "旋转TF徽标",
        fre: "Logo TF tournant",
        bra: "Logo do TF circulante",
        hun: "Keringő TF logó",
        swe: "Cirklande TF-Logga"
    },
    5: {
        img: "green_confetti",
        eng: "Green Confetti",
        schema: 6,
        pol: "zielone konfetti",
        sch: "绿色彩带",
        fre: "Confetti verts",
        bra: "Confete verde",
        hun: "Zöld konfetti",
        swe: "Grön Konfetti"
    },
    6: {
        img: "green_energy",
        eng: "Green Energy",
        schema: 9,
        pol: "zielona energia",
        sch: "绿色能量",
        fre: "Énergie verte",
        bra: "Energia verde",
        hun: "Zöld energia",
        swe: "Grön Energi"
    },
    7: {
        img: "haunted_ghosts",
        eng: "Haunted Ghosts",
        schema: 8,
        pol: "straszliwe duchy",
        sch: "灵魂附体",
        fre: "Fantômes",
        bra: "Fantasmas assombrados",
        hun: "Kísértő szellemek",
        swe: "Hemsökta Spöken"
    },
    8: {
        img: "massed_flies",
        eng: "Massed Flies",
        schema: 12,
        pol: "chmara much",
        sch: "苍蝇云集",
        fre: "Cortège de mouches",
        bra: "Moscas em massa",
        hun: "Legyek",
        swe: "Flugsvärm"
    },
    9: {
        img: "purple_confetti",
        eng: "Purple Confetti",
        schema: 7,
        pol: "fioletowe konfetti",
        sch: "紫色彩带",
        fre: "Confetti violets",
        bra: "Confete roxo",
        hun: "Lila konfetti",
        swe: "Lila Konfetti"
    },
    10: {
        img: "purple_energy",
        eng: "Purple Energy",
        schema: 10,
        pol: "fioletowa energia",
        sch: "紫色能量",
        fre: "Énergie violette",
        bra: "Energia roxa",
        hun: "Lila energia",
        swe: "Lila Energi"
    },
    11: {
        img: "scorching_flames",
        eng: "Scorching Flames",
        schema: 14,
        pol: "piekące płomienie",
        sch: "炉火纯青",
        fre: "Flammes écorcheuses",
        bra: "Chamas escaldantes",
        hun: "Perzselő lángok",
        swe: "Brännheta Lågor"
    },
    12: {
        img: "searing_plasma",
        eng: "Searing Plasma",
        schema: 15,
        pol: "paląca plazma",
        sch: "剧烈离子体",
        fre: "Plasma brûlant",
        bra: "Plasma escaldante",
        hun: "Égető plazma",
        swe: "Glödande Plasma"
    },
    13: {
        img: "sunbeams",
        eng: "Sunbeams",
        schema: 17,
        pol: "promienie Słońca",
        sch: "太阳之光",
        fre: "Rayons de soleil",
        bra: "Raios de sol",
        hun: "Napsugarak",
        swe: "Solstrålar"
    },
    14: {
        img: "vivid_plasma",
        eng: "Vivid Plasma",
        schema: 16,
        pol: "żywa plazma",
        sch: "活泼离子体",
        fre: "Plasma éclatant",
        bra: "Plasma vívido",
        hun: "Élénk plazma",
        rom: "Plasmă Strălucitoare",
        swe: "Intensiv Plasma"
    },
    15: {
        img: "72",
        eng: "'72",
        schema: 3004,
        pol: "1972",
        sch: "炫目舞会",
        fre: "'72",
        bra: "1972"
    },
    16: {
        img: "fountain_of_delight",
        eng: "Fountain of Delight",
        schema: 3005,
        pol: "fontanna rozkoszy",
        sch: "快乐之源",
        fre: "Fontaine des Délices",
        bra: "Fonte do deleite",
        hun: "Élvezet szökőkútja",
        swe: "Glädjefontän"
    },
    17: {
        img: "holy_grail",
        eng: "Holy Grail",
        schema: 3003,
        pol: "Święty Graal",
        sch: "圣杯之光",
        fre: "Saint Graal",
        bra: "Santo Graal",
        hun: "Szent Grál",
        rom: "Sfântul Graal",
        swe: "Helig graal"
    },
    18: {
        img: "mega_strike",
        eng: "Mega Strike",
        schema: 3010,
        pol: "megapiorun",
        sch: "超级闪电",
        fre: "Frappe foudroyante",
        bra: "Mega golpe",
        hun: "Mega csapás",
        swe: "Megaslag"
    },
    19: {
        img: "midnight_whirlwind",
        eng: "Midnight Whirlwind",
        schema: 3008,
        pol: "północne tornado",
        sch: "午夜风暴",
        fre: "Tornade de minuit",
        bra: "Redemoinho da meia-noite",
        hun: "Éjféli forgószél",
        swe: "Midnattsvirvelvind"
    },
    20: {
        img: "screaming_tiger",
        eng: "Screaming Tiger",
        schema: 3006,
        pol: "ryczący tygrys",
        sch: "火球环绕",
        fre: "Tigre hurlant",
        bra: "Tigre rugidor",
        hun: "Üvöltő tigris",
        swe: "Skrikande Tigern"
    },
    21: {
        img: "showstopper",
        eng: "Showstopper",
        schema: 3001,
        pol: "fajerwerki",
        sch: "烟花表演",
        fre: "Clou du spectacle",
        bra: "Fim de show",
        hun: "Ünneprontó",
        swe: "Showstoppare"
    },
    22: {
        img: "silver_cyclone",
        eng: "Silver Cyclone",
        schema: 3009,
        pol: "srebrny cyklon",
        sch: "白银旋风",
        fre: "Tornade d'argent",
        bra: "Ciclone prateado",
        hun: "Ezüst ciklon",
        swe: "Silvercyklon"
    },
    23: {
        img: "skill_gotten_gains",
        eng: "Skill Gotten Gains",
        schema: 3007,
        pol: "umiejętnie wycwaniona forsa",
        sch: "土豪光环",
        fre: "Biens bien acquis",
        bra: "Dinheiro sujo",
        hun: "Ügyesnek áll a világ",
        swe: "Erfaret förtjänta pengar"
    },
    24: {
        img: "isotope",
        eng: "Isotope",
        schema: 702,
        pol: "izotop",
        sch: "绿色辐射",
        fre: "Isotope",
        bra: "Isótopo",
        hun: "Izotóp",
        swe: "Isotop"
    },
    25: {
        img: "hot",
        eng: "Hot",
        schema: 701,
        pol: "żar",
        sch: "炽热发烟",
        fre: "Chaud",
        bra: "Quente",
        hun: "Forróság",
        swe: "Varm"
    },
    26: {
        img: "cool",
        eng: "Cool",
        schema: 703,
        pol: "chłód",
        sch: "冷若冰霜",
        fre: "Frais",
        bra: "Frio",
        hun: "Hűvösség",
        swe: "Kall"
    },
    27: {
        img: "energy_orb",
        eng: "Energy Orb",
        schema: 704,
        pol: "kula energii",
        sch: "能量光束",
        fre: "Orbe d'énergie",
        bra: "Orbe de energia",
        hun: "Energiagömb",
        swe: "Energiklot"
    },
    28: {
        img: "blizzardy_storm",
        eng: "Blizzardy Storm",
        schema: 30,
        pol: "śnieżna zamieć",
        sch: "暴雪云",
        fre: "Tempête et Blizzard",
        bra: "Tempestade de neve",
        hun: "Hóvihar",
        swe: "Snöig Storm"
    },
    29: {
        img: "bubbling",
        eng: "Bubbling",
        schema: 34,
        pol: "bąbelki",
        sch: "沤浮泡影",
        fre: "Bullant",
        bra: "Borbulhando",
        hun: "Buborékok",
        swe: "Bubblande"
    },
    30: {
        img: "nuts_n_bolts",
        eng: "Nuts n' Bolts",
        schema: 31,
        pol: "śrubki i nakrętki",
        sch: "螺栓和螺母",
        fre: "Écrous & Boulons",
        bra: "Porcas e parafusos",
        hun: "Anyák és csavarok",
        swe: "Muttrar å Skruvar"
    },
    31: {
        img: "orbiting_fire",
        eng: "Orbiting Fire",
        schema: 33,
        pol: "błędny ognik",
        sch: "火焰缠绕",
        fre: "Flamme en Orbite",
        bra: "Fogo orbitante",
        hun: "Keringő tűzcsóva",
        swe: "Kretsande Eld"
    },
    32: {
        img: "orbiting_planets",
        eng: "Orbiting Planets",
        schema: 32,
        pol: "układ planetarny",
        sch: "行星环绕",
        fre: "Planètes en Orbite",
        bra: "Planetas orbitantes",
        hun: "Keringő bolygók",
        swe: "Kretsande Planeter"
    },
    33: {
        img: "smoking",
        eng: "Smoking",
        schema: 35,
        pol: "dym",
        sch: "乌烟瘴气",
        fre: "Fumée",
        bra: "Fumante",
        hun: "Füstölgés",
        swe: "Rykande"
    },
    34: {
        img: "steaming",
        eng: "Steaming",
        schema: 36,
        pol: "para",
        sch: "蒸汽腾腾",
        fre: "Vapeur",
        bra: "Soltando vapor",
        hun: "Gőzölgés",
        swe: "Ångande"
    },
    35: {
        img: "stormy_storm",
        eng: "Stormy Storm",
        schema: 29,
        pol: "burzliwa burza",
        sch: "暴雨云",
        fre: "Tempête et Éclairs",
        bra: "Tempestade tempestuosa",
        hun: "Zápor-zivatar",
        swe: "Stormig Storm"
    },
    36: {
        img: "cauldron_bubbles",
        eng: "Cauldron Bubbles",
        schema: 39,
        pol: "bąbelki z kociołka",
        sch: "魔埚气泡",
        fre: "Bulles de Chaudron",
        bra: "Bolhas de caldeirão",
        hun: "Bugyborékoló üst",
        swe: "Kittelbubblor"
    },
    37: {
        img: "cloudy_moon",
        eng: "Cloudy Moon",
        schema: 38,
        pol: "zachmurzony księżyc",
        sch: "乌云盖月",
        fre: "Lune voilée",
        bra: "Lua nublada",
        hun: "Felhős hold",
        swe: "Molnig Måne"
    },
    38: {
        img: "eerie_orbiting_fire",
        eng: "Eerie Orbiting Fire",
        schema: 40,
        pol: "straszliwy błędny ognik",
        sch: "妖娆灵焰",
        fre: "Sinistre flamme en orbite",
        bra: "Fogo assustador orbitante",
        hun: "Hátborzongató keringő tűz",
        swe: "Kuslig Kretsande Eld"
    },
    39: {
        img: "flaming_lantern",
        eng: "Flaming Lantern",
        schema: 37,
        pol: "płonąca latarnia",
        sch: "火焰南瓜",
        fre: "Lanterne enflammée",
        bra: "Lanterna flamejante",
        hun: "Lángoló lámpás",
        swe: "Flammande Lykta"
    },
    40: {
        img: "harvest_moon",
        eng: "Harvest Moon",
        schema: 45,
        pol: "urodzajny księżyc",
        sch: "牧场物语",
        fre: "Lune des moissons",
        bra: "Lua de colheita",
        hun: "Holdtölte",
        swe: "Skördemåne"
    },
    41: {
        img: "its_a_secret_to_everybody",
        eng: "It's A Secret To Everybody",
        schema: 46,
        pol: "dla wszystkich jest to sekretem",
        sch: "潜影骷髅",
        fre: "C'est un secret pour tout le monde",
        bra: "É um segredo para todos",
        hun: "Senki sem tud róla",
        swe: "Det är en hemlighet för alla"
    },
    42: {
        img: "knifestorm",
        eng: "Knifestorm",
        schema: 43,
        pol: "ostra ulewa",
        sch: "刀光剑影",
        fre: "Couteaunnerre",
        bra: "Tempestade de facas",
        hun: "Késvihar",
        swe: "Knivstorm"
    },
    43: {
        img: "misty_skull",
        eng: "Misty Skull",
        schema: 44,
        pol: "tajemnicza czaszka",
        sch: "迷雾骷髅",
        fre: "Crâne mystique",
        bra: "Caveira nebulosa",
        hun: "Ködös koponya",
        swe: "Dimmig Dödskalle"
    },
    44: {
        img: "stormy_13th_hour",
        eng: "Stormy 13th Hour",
        schema: 47,
        pol: "trzynasta burzliwa godzina",
        sch: "诅咒风暴",
        fre: "Orage de la 13ème heure",
        bra: "13ª hora tempestuosa",
        hun: "Viharos 13. óra",
        swe: "Den Stormiga 13:nde Timmen"
    },
    45: {
        img: "anti_freeze",
        eng: "Anti-Freeze",
        schema: 69,
        pol: "płyn chłodzący",
        sch: "冰霜之抵",
        fre: "Antigel",
        bra: "Anticongelante",
        hun: "Fagyálló",
        swe: "Antifrys"
    },
    46: {
        img: "electrostatic",
        eng: "Electrostatic",
        schema: 67,
        pol: "elektrostatyczność",
        sch: "静电火花",
        fre: "Électrostatique",
        bra: "Eletrostático",
        hun: "Elektrosztatikus",
        swe: "Elektrostatisk"
    },
    47: {
        img: "green_black_hole",
        eng: "Green Black Hole",
        schema: 71,
        pol: "zielona czarna dziura",
        sch: "绿色黑洞",
        fre: "Trou noir vert",
        bra: "Buraco negro verde",
        hun: "Zöld fekete lyuk",
        swe: "Grönt svart hål"
    },
    48: {
        img: "memory_leak",
        eng: "Memory Leak",
        schema: 65,
        pol: "wyciek pamięci",
        sch: "内存溢出",
        fre: "Fuite mémoire",
        bra: "Vazamento de memória",
        hun: "Memóriaszivárgás",
        rom: "Scurgere de memorie",
        swe: "Minnesläcka"
    },
    49: {
        img: "overclocked",
        eng: "Overclocked",
        schema: 66,
        pol: "podkręcenie",
        sch: "超频过度",
        fre: "Overclocké",
        bra: "Overclock",
        hun: "Tuningolt",
        swe: "Överklockad"
    },
    50: {
        img: "phosphorous",
        eng: "Phosphorous",
        schema: 63,
        pol: "fosfor",
        sch: "磷火",
        fre: "Phosphorescent",
        bra: "Fosforoso",
        hun: "Foszforos",
        swe: "Fosforhaltigt"
    },
    51: {
        img: "power_surge",
        eng: "Power Surge",
        schema: 68,
        pol: "przepięcie",
        sch: "电弧放射",
        fre: "Surtension",
        bra: "Surto de energia",
        hun: "Túlfeszültség",
        swe: "Energivåg"
    },
    52: {
        img: "roboactive",
        eng: "Roboactive",
        schema: 72,
        pol: "roboaktywność",
        sch: "机情紫露",
        fre: "Roboactif",
        bra: "Roboativo",
        hun: "Roboaktív",
        swe: "Roboaktiv"
    },
    53: {
        img: "sulphurous",
        eng: "Sulphurous",
        schema: 64,
        pol: "siarka",
        sch: "硫火",
        fre: "Sulfureux",
        bra: "Sulfuroso",
        hun: "Kénes",
        rom: "Sulfuros",
        swe: "Svavelaktig"
    },
    54: {
        img: "time_warp",
        eng: "Time Warp",
        schema: 70,
        pol: "zakrzywienie czasu",
        sch: "时间扭曲",
        fre: "Distorsion temporelle",
        bra: "Viagem no tempo",
        hun: "Időhurok",
        swe: "Tidsförvrängning"
    },
    55: {
        img: "aces_high",
        eng: "Aces High",
        schema: 59,
        pol: "asy pik",
        sch: "头牌高悬",
        fre: "As des As",
        bra: "Ás de espadas",
        hun: "Magas ász",
        swe: "Flygande äss"
    },
    56: {
        img: "cloud_9",
        eng: "Cloud 9",
        schema: 58,
        pol: "niczym w niebie",
        sch: "魔心连环",
        fre: "Sur un petit nuage",
        bra: "Nuvem 9",
        hun: "Szívfelhő",
        swe: "Moln 9"
    },
    57: {
        img: "dead_presidents",
        eng: "Dead Presidents",
        schema: 60,
        pol: "duży nominał",
        sch: "总统之殇",
        fre: "Présidents morts",
        bra: "Verdinhas",
        hun: "Halott elnökök",
        swe: "Döda Presidenter"
    },
    58: {
        img: "disco_beat_down",
        eng: "Disco Beat Down",
        schema: 62,
        pol: "w błysku disco",
        sch: "疯狂迪斯科",
        fre: "Fièvre du Disco",
        bra: "Estouro bacana",
        hun: "Diszkóláz",
        swe: "Discokväll"
    },
    59: {
        img: "kill_a_watt",
        eng: "Kill-a-Watt",
        schema: 56,
        pol: "gigawat",
        sch: "金色电流",
        fre: "Wattueur",
        bra: "Eletromatador",
        hun: "Kilőwatt",
        swe: "Död-a-Watt"
    },
    60: {
        img: "miami_nights",
        eng: "Miami Nights",
        schema: 61,
        pol: "noce Miami",
        sch: "迈阿密之夜",
        fre: "Miami Nights",
        bra: "Noites de Miami",
        hun: "Miami éjszakák",
        swe: "Miaminätter"
    },
    61: {
        img: "terror_watt",
        eng: "Terror-Watt",
        schema: 57,
        pol: "megawat",
        sch: "青色电流",
        fre: "Terreur-Watt",
        bra: "Eleterrorficado",
        hun: "Terrorwatt"
    },
    62: {
        img: "arcana",
        eng: "Arcana",
        schema: 73,
        pol: "arkana",
        sch: "奥秘之髓",
        fre: "Arcane",
        bra: "Arcano",
        hun: "Titokzatos",
        swe: "Arkanisk"
    },
    63: {
        img: "chiroptera_venenata",
        eng: "Chiroptera Venenata",
        schema: 75,
        pol: "chiroptera venenata",
        sch: "幽冥之火",
        fre: "Chiroptera Venenata",
        bra: "Chiroptera venenata",
        ger: "Giftige Fledermaus"
    },
    64: {
        img: "darkblaze",
        eng: "Darkblaze",
        schema: 79,
        pol: "ciemny płomień",
        sch: "黑暗大火",
        fre: "Flammes maléfiques",
        bra: "Labareda sombria",
        hun: "Sötét láng",
        swe: "Mörk Eld"
    },
    65: {
        img: "demonflame",
        eng: "Demonflame",
        schema: 80,
        pol: "demoniczny płomień",
        sch: "恶魔之火",
        fre: "Flammes démoniaques",
        bra: "Chama demoníaca",
        hun: "Démoni láng",
        swe: "Demonflamma"
    },
    66: {
        img: "hellfire",
        eng: "Hellfire",
        schema: 78,
        pol: "piekielny ogień",
        sch: "地狱之焰",
        fre: "Flammes infernales",
        bra: "Fogo infernal",
        hun: "Pokoltűz",
        swe: "Helveteseld"
    },
    67: {
        img: "poisoned_shadows",
        eng: "Poisoned Shadows",
        schema: 76,
        pol: "zatrute cienie",
        sch: "毒蕈之影",
        fre: "Ombre empoisonnée",
        bra: "Veneno sombrio",
        hun: "Mérgezett árnyak",
        swe: "Förgiftade Skuggor"
    },
    68: {
        img: "something_burning_this_way_comes",
        eng: "Something Burning This Way Comes",
        schema: 77,
        pol: "jakiś płomień tu nadchodzi",
        sch: "炼狱之路",
        fre: "Ombre enflammée",
        bra: "Arder ou não arder",
        hun: "Lángoló lélek közeleg",
        swe: "Något Brinnande Hitåt Kommer"
    },
    69: {
        img: "spellbound",
        eng: "Spellbound",
        schema: 74,
        pol: "urok",
        sch: "混乱魔咒",
        fre: "Envoutement",
        bra: "Feitiço de artifício",
        hun: "Varázsos",
        swe: "Trollbunden"
    },
    70: {
        img: "amaranthine",
        eng: "Amaranthine",
        schema: 82,
        pol: "amarant",
        sch: "不朽之灵",
        fre: "Amarante",
        bra: "Amarantino",
        hun: "Amaránt",
        swe: "Amarantin"
    },
    71: {
        img: "bonzo_the_all_gnawing",
        eng: "Bonzo The All-Gnawing",
        schema: 81,
        pol: "wszechjedzący Bonzo",
        sch: "地下巨口",
        fre: "Bonzo The All-Gnawing",
        bra: "Bonzo, o atormentador",
        hun: "Bonzó, a Mindentrágó",
        swe: "Bonzo, den evigt gnagande"
    },
    72: {
        img: "ghastly_ghosts_jr",
        eng: "Ghastly Ghosts Jr",
        schema: 85,
        pol: "młodsze upiorne upiory",
        fre: "Horribles fantômes Jr",
        sch: "紫色小鬼魂",
        bra: "Fantasminhas fantasmagóricos",
        hun: "Ifj. Szellemes szellemek",
        swe: "Gastande minigastar"
    },
    73: {
        img: "haunted_phantasm_jr",
        eng: "Haunted Phantasm Jr",
        schema: 86,
        pol: "małe nawiedzone widziadło",
        sch: "青色小鬼魂",
        fre: "Fantôme hanteur Jr.",
        bra: "Fantasminha assombrado",
        hun: "ifj. Kísértő Látomás",
        swe: "Lilla hemsökande spöket"
    },
    74: {
        img: "stare_from_beyond",
        eng: "Stare From Beyond",
        schema: 83,
        pol: "spojrzenie z zaświatów",
        sch: "坟墓之眼",
        fre: "Regards de l'au-delà",
        bra: "Olhar do além",
        hun: "Túlvilági tekintet",
        swe: "Blicken från bakom"
    },
    75: {
        img: "ooze",
        eng: "Ooze",
        schema: 84,
        pol: "szlam",
        sch: "泥中有眼",
        fre: "La Bourbe",
        bra: "O Pantanoso",
        hun: "A Nyálka",
        rom: "The Ooze",
        swe: "Slemmet"
    },
    76: {
        img: "death_at_dusk",
        eng: "Death at Dusk",
        schema: 90,
        pol: "śmierć o zmroku",
        sch: "落日",
        fre: "Mort crépusculaire",
        bra: "Morte ao anoitecer",
        hun: "Esti elmúlás",
        swe: "Solnedgång"
    },
    77: {
        img: "frostbite",
        eng: "Frostbite",
        schema: 87,
        pol: "odmrożenie",
        sch: "寒霜",
        fre: "Engelure",
        bra: "Congelamento",
        hun: "Dércsípés",
        swe: "Köldskada"
    },
    78: {
        img: "molten_mallard",
        eng: "Molten Mallard",
        schema: 88,
        pol: "stopiona kaczka krzyżówka",
        sch: "鸭子焰火",
        fre: "Canartifice",
        bra: "Gratinado",
        hun: "Vörösizzó vadkacsa",
        swe: "Sprängd anka"
    },
    79: {
        img: "morning_glory",
        eng: "Morning Glory",
        schema: 89,
        pol: "poranna chwała",
        sch: "晨曦",
        fre: "Splendeur matinale",
        bra: "Glória da manhã",
        hun: "Reggeli ragyogás",
        swe: "Soluppgång"
    },
    80: {
        img: "abduction",
        eng: "Abduction",
        schema: 91,
        pol: "porwanie",
        sch: "吸收光束",
        fre: "Enlèvement",
        bra: "Abdução",
        hun: "Elrablás",
        swe: "Bortförande"
    },
    81: {
        img: "atomic",
        eng: "Atomic",
        schema: 92,
        pol: "atomy",
        sch: "原子核",
        fre: "Atomique",
        bra: "Atômico",
        hun: "Atomi",
        swe: "Atomisk"
    },
    82: {
        img: "subatomic",
        eng: "Subatomic",
        schema: 93,
        pol: "subatomy",
        sch: "亚原子粒子",
        fre: "Subatomique",
        bra: "Subatômico",
        hun: "Szubatomi",
        swe: "Subatomär"
    },
    83: {
        img: "electric_hat_protector",
        eng: "Electric Hat Protector",
        schema: 94,
        pol: "elektryczna bariera ochronna",
        sch: "电弧保护罩",
        fre: "Protection électrique",
        bra: "Protetor elétrico de chapéus",
        hun: "Elektromos sapkavédő",
        swe: "Elektrisk hattskyddare"
    },
    84: {
        img: "magnetic_hat_protector",
        eng: "Magnetic Hat Protector",
        schema: 95,
        pol: "magnetyczna bariera ochronna",
        sch: "磁能保护罩",
        fre: "Protection magnétique",
        bra: "Protetor magnético de chapéus",
        hun: "Mágneses sapkavédő",
        swe: "Magnetisk hattskyddare"
    },
    85: {
        img: "voltaic_hat_protector",
        eng: "Voltaic Hat Protector",
        schema: 96,
        pol: "galwaniczna bariera ochronna",
        sch: "高压保护罩",
        fre: "Protection voltaïque",
        bra: "Protetor voltaico de chapéus",
        hun: "Voltaikus sapkavédő",
        swe: "Spänning-hattskyddare"
    },
    86: {
        img: "galactic_codex",
        eng: "Galactic Codex",
        schema: 97,
        pol: "kodeks galaktyczny",
        sch: "星际密码",
        fre: "Codex galactique",
        bra: "Códice galático",
        hun: "Galaktikus Kódex",
        swe: "Galaktiskt kodex"
    },
    87: {
        img: "ancient_codex",
        eng: "Ancient Codex",
        schema: 98,
        pol: "kodeks starożytnych",
        sch: "远古密码",
        fre: "Codex ancien",
        bra: "Códice ancestral",
        hun: "Ősi Kódex",
        swe: "Antikt kodex"
    },
    88: {
        img: "nebula",
        eng: "Nebula",
        schema: 99,
        pol: "mgławica",
        sch: "星云",
        fre: "Nébuleuse",
        bra: "Nébula",
        hun: "Csillagköd",
        ger: "Nebel"
    },
    89: {
        img: "death_by_disco",
        eng: "Death by Disco",
        schema: 100,
        pol: "śmierć w rytmie disco",
        sch: "骷髅蹦迪",
        fre: "Disco de la mort",
        bra: "Requebrando o esqueleto",
        hun: "Diszkó általi halál",
        swe: "Dog av Disco"
    },
    90: {
        img: "its_a_mystery_to_everyone",
        eng: "It's a mystery to everyone",
        schema: 101,
        pol: "zagadka dla wszystkich",
        sch: "紫色幽灵",
        fre: "C'est un mystère pour tout le monde",
        bra: "É um mistério",
        hun: "Rejtély mindenki számára",
        swe: "Det är ett mysterium för alla"
    },
    91: {
        img: "its_a_puzzle_to_me",
        eng: "It's a puzzle to me",
        schema: 102,
        pol: "zagadka dla mnie",
        sch: "绿色幽灵",
        fre: "C'est une énigme pour moi ",
        bra: "É uma charada",
        hun: "Rejtély számomra",
        cze: "It's a puzzle to me ",
        rom: "It's a puzzle to me ",
        swe: "Det är en gåta för mig"
    },
    92: {
        img: "ether_trail",
        eng: "Ether Trail",
        schema: 103,
        pol: "ślad eteru",
        sch: "幽冥之痕",
        fre: "Trainée céleste",
        bra: "Rastro etéreo",
        hun: "Éteri csóva",
        swe: "Eterspår"
    },
    93: {
        img: "nether_trail",
        eng: "Nether Trail",
        schema: 104,
        pol: "ślad piekieł",
        sch: "诡异之痕",
        fre: "Trainée infernale",
        bra: "Rastro sombrio",
        hun: "Alvilági csóva",
        swe: "Nedrespår"
    },
    94: {
        img: "ancient_eldritch",
        eng: "Ancient Eldritch",
        schema: 105,
        pol: "prastare dziwactwo",
        sch: "远古邪灵",
        fre: "Feu Eldritch",
        bra: "Assombração ancestral",
        hun: "Ősi rémség",
        swe: "Uråldrigt Spöke"
    },
    95: {
        img: "eldritch_flame",
        eng: "Eldritch Flame",
        schema: 106,
        pol: "nadprzyrodzony płomień",
        sch: "邪灵之焰",
        fre: "Flamme d'Eldritch",
        bra: "Chama ancestral",
        hun: "Rémséges láng",
        swe: "Spökeld"
    },
    96: {
        img: "neutron_star",
        eng: "Neutron Star",
        schema: 107,
        pol: "gwiazda neutronowa",
        sch: "中子星",
        fre: "Étoile à neutrons",
        bra: "Estrela de nêutron",
        hun: "Neutroncsillag",
        swe: "Neutronstjärna"
    },
    97: {
        img: "starstorm_slumber",
        eng: "Starstorm Slumber",
        schema: 110,
        pol: "gwieździsty sen",
        sch: "蓝色流星雨",
        fre: "Sommeil étoilé",
        bra: "Sonolência estelar",
        hun: "Csillagzáporos szunyókálás",
        swe: "Stjärnstormslummer"
    },
    98: {
        img: "starstorm_insomnia",
        eng: "Starstorm Insomnia",
        schema: 109,
        pol: "gwiaździsta bezsenność",
        sch: "绿色流星雨",
        fre: "Insomnie étoilée",
        bra: "Insônia estelar",
        hun: "Csillagzáporos álmatlanság",
        swe: "Stjärnstormsömnlöshet"
    },
    99: {
        img: "tesla_coil",
        eng: "Tesla Coil",
        schema: 108,
        pol: "transformator Tesli",
        sch: "磁暴线圈",
        fre: "Bobine Tesla",
        bra: "Bobina de Tesla",
        hun: "Tesla-tekercs",
        swe: "Teslaspole"
    },
    100: {
        img: "brain_drain",
        eng: "Brain Drain",
        schema: 111,
        pol: "drenaż mózgu",
        sch: "脑浆外泄",
        fre: "Fuite des cerveaux",
        bra: "Vazão cerebral",
        hun: "Agyszipka",
        swe: "Hjärntömning"
    },
    101: {
        img: "open_mind",
        eng: "Open Mind",
        schema: 112,
        pol: "otwarty umysł",
        sch: "脑洞大开",
        fre: "Esprit ouvert",
        bra: "Mente aberta",
        hun: "Nyitott elme",
        swe: "Öppet sinne"
    },
    102: {
        img: "head_of_steam",
        eng: "Head of Steam",
        schema: 113,
        pol: "parująca głowa",
        sch: "脑浆迸裂",
        fre: "À toute vapeur",
        bra: "Cabeça fervente",
        hun: "Gőzbugyor",
        swe: "Huvudvärk"
    },
    103: {
        img: "galactic_gateway",
        eng: "Galactic Gateway",
        schema: 114,
        pol: "galaktyczne przejście",
        sch: "星界之门",
        fre: "Portail intergalactique",
        bra: "Noite nebulosa",
        hun: "Galaktikus átjáró",
        swe: "Galaktisk portal"
    },
    104: {
        img: "the_eldritch_opening",
        eng: "Eldritch Opening",
        schema: 115,
        pol: "nadprzyrodzone przejście",
        sch: "神秘之门",
        fre: "Passage funeste",
        bra: "Limiar sobrenatural",
        hun: "A vérfagyasztó nyiladék",
        rom: "The Eldritch Opening",
        swe: "Kuslig skymning"
    },
    105: {
        img: "the_dark_doorway",
        eng: "Dark Doorway",
        schema: 116,
        pol: "mroczne przejście",
        sch: "黑暗之门",
        fre: "Voie lugubre",
        bra: "Portão da penumbra",
        hun: "A sötét kapu",
        rom: "The Dark Doorway",
        swe: "Mörk dörröppning"
    },
    106: {
        img: "ring_of_fire",
        eng: "Ring of Fire",
        schema: 117,
        pol: "pierścień ognia",
        sch: "烈焰之环",
        fre: "Anneau de feu",
        bra: "Círculo de fogo",
        hun: "Tűzgyűrű",
        swe: "Eldring"
    },
    107: {
        img: "vicious_circle",
        eng: "Vicious Circle",
        schema: 118,
        pol: "błędne koło",
        sch: "堕落邪环",
        fre: "Cercle vicieux",
        bra: "Círculo vicioso",
        hun: "Gonosz kör",
        swe: "Ond cirkel"
    },
    108: {
        img: "white_lightning",
        eng: "White Lightning",
        schema: 119,
        pol: "biała błyskawica",
        sch: "白光之环",
        fre: "Éclair étincelant",
        bra: "Relâmpago reluzente",
        hun: "Fehér fény",
        swe: "Vitt ljus"
    },
    109: {
        img: "omniscient_orb",
        eng: "Omniscient Orb",
        schema: 120,
        pol: "kryształowa kula",
        sch: "全知之球",
        fre: "Orbe de l'omniscience",
        bra: "Orbe onisciente",
        hun: "Mindentudó gömb",
        swe: "Allvetande kula"
    },
    110: {
        img: "clairvoyance",
        eng: "Clairvoyance",
        schema: 121,
        pol: "jasnowidzenie",
        sch: "全知之眼",
        fre: "Clairvoyance",
        bra: "Clarividência",
        hun: "Harmadik szem",
        swe: "Klärvoajans"
    },
    111: {
        img: "fifth_dimension",
        eng: "Fifth Dimension",
        schema: 122,
        pol: "piąty wymiar",
        fre: "Cinquième dimension",
        sch: "第五维度",
        bra: "Quinta dimensão",
        hun: "Ötödik dimenzió",
        swe: "Femte dimensionen"
    },
    112: {
        img: "vicious_vortex",
        eng: "Vicious Vortex",
        schema: 123,
        pol: "występny wir",
        fre: "Vortex vicieux",
        sch: "邪恶漩涡",
        bra: "Turbilhão turbulento",
        hun: "Őrjítő örvény",
        swe: "Våldsam virvelvind"
    },
    113: {
        img: "menacing_miasma",
        eng: "Menacing Miasma",
        schema: 124,
        pol: "makabryczny miazmat",
        fre: "Miasme menaçant",
        sch: "恶毒瘴气",
        bra: "Miasma medonho",
        hun: "Komisz kipárolgás",
        swe: "Mystisk miasma"
    },
    114: {
        img: "abyssal_aura",
        eng: "Abyssal Aura",
        schema: 125,
        pol: "aura otchłani",
        fre: "Aura abyssale",
        sch: "深渊光环",
        bra: "Aura abissal",
        hun: "Alvilági aura",
        ger: "Abgrundtiefe Aura"
    },
    115: {
        img: "wicked_wood",
        eng: "Wicked Wood",
        schema: 126,
        pol: "nikczemny las",
        fre: "Forêt funeste",
        sch: "暗影之林",
        bra: "Madeira maligna",
        hun: "Borzasztó bozótos",
        swe: "Spökande skog"
    },
    116: {
        img: "ghastly_grove",
        eng: "Ghastly Grove",
        schema: 127,
        pol: "upiorny gaj",
        fre: "Bosquet hanté",
        sch: "恐怖之林",
        bra: "Floresta fantasmagórica",
        hun: "Lidérces liget",
        swe: "Dunkel dunge"
    },
    117: {
        img: "mystical_medley",
        eng: "Mystical Medley",
        schema: 128,
        pol: "mistyczna mieszanina",
        fre: "Mélange mystique",
        sch: "神秘之涌",
        bra: "Misto místico",
        hun: "Misztikus mindenféle",
        swe: "Mystisk magi"
    },
    118: {
        img: "ethereal_essence",
        eng: "Ethereal Essence",
        schema: 129,
        pol: "eteryczna esencja",
        fre: "Essence éthérée",
        sch: "飘渺之华",
        bra: "Essência etérea",
        hun: "Éteri esszencia",
        swe: "Eterisk essens"
    },
    119: {
        img: "twisted_radiance",
        eng: "Twisted Radiance",
        schema: 130,
        pol: "pokrętne promieniowanie",
        fre: "Rayonnement rocambolesque",
        sch: "扭曲之辉",
        bra: "Espiral do resplendor",
        hun: "Rontó ragyogás",
        swe: "Strålande sken"
    },
    120: {
        img: "violet_vortex",
        eng: "Violet Vortex",
        schema: 131,
        pol: "wrzosowy wir",
        fre: "Vortex violet",
        sch: "紫色漩涡",
        bra: "Vórtice violeta",
        hun: "Ibolyaszín örvény",
        swe: "Violett virvelvind"
    },
    121: {
        img: "verdant_vortex",
        eng: "Verdant Vortex",
        schema: 132,
        pol: "zielenisty wir",
        fre: "Vortex verdoyant",
        sch: "翠绿漩涡",
        bra: "Vórtice verdejante",
        hun: "Zöldellő örvény",
        swe: "Smaragdgrön storm"
    },
    122: {
        img: "valiant_vortex",
        eng: "Valiant Vortex",
        schema: 133,
        pol: "wiśniowy wir",
        fre: "Vortex vaillant",
        sch: "英勇漩涡",
        bra: "Vórtice valoroso",
        hun: "Hősies örvény",
        swe: "Tapper turbulens"
    },
    123: {
        img: "sparkling_lights",
        eng: "Sparkling Lights",
        schema: 134,
        pol: "migoczące światełka",
        fre: "Lumières étincelantes",
        sch: "浮游彩光",
        bra: "Brilho cintilante",
        hun: "Sziporkázó fények",
        swe: "Gnistrande ljus"
    },
    124: {
        img: "frozen_icefall",
        eng: "Frozen Icefall",
        schema: 135,
        pol: "spadające sople",
        fre: "Chute de neige",
        sch: "冰瀑",
        bra: "Cascata congelada",
        hun: "Fagyott jégeső",
        swe: "Fallande istappar"
    },
    125: {
        img: "fragmented_gluons",
        eng: "Fragmented Gluons",
        schema: 136,
        pol: "rozproszone gluony",
        fre: "Gluons fragmentés",
        sch: "胶子",
        bra: "Glúons fragmentados",
        hun: "Töredezett gluonok",
        swe: "Fragmenterade gluoner"
    },
    126: {
        img: "fragmented_quarks",
        eng: "Fragmented Quarks",
        schema: 137,
        pol: "rozproszone kwarki",
        fre: "Quarks fragmentés",
        sch: "夸克",
        bra: "Quarks fragmentados",
        hun: "Töredezett kvarkok",
        swe: "Fragmenterade kvarkar"
    },
    127: {
        img: "fragmented_photons",
        eng: "Fragmented Photons",
        schema: 138,
        pol: "rozproszone fotony",
        fre: "Photons fragmentés",
        sch: "光子",
        bra: "Fótons fragmentados",
        hun: "Töredezett fotonok",
        swe: "Fragmenterade fotoner"
    },
    128: {
        img: "defragmenting_reality",
        eng: "Defragmenting Reality",
        schema: 139,
        pol: "defragmentacja rzeczywistości",
        fre: "Réalité défragmentée",
        sch: "现实重构",
        bra: "Desfragmentando a realidade",
        hun: "Valóság-töredezettségmentesítés",
        swe: "Defragmenterar verkligheten"
    },
    129: {
        img: "fragmenting_reality",
        eng: "Fragmenting Reality",
        schema: 141,
        pol: "fragmentacja rzeczywistości",
        fre: "Réalité fragmentée",
        sch: "现实解体",
        bra: "Fragmentando a realidade",
        hun: "Töredező valóság",
        swe: "Fragmenterar verkligheten"
    },
    130: {
        img: "refragmenting_reality",
        eng: "Refragmenting Reality",
        schema: 142,
        pol: "refragmentacja rzeczywistości",
        fre: "Réalité réfragmentée",
        sch: "现实重组",
        bra: "Realidade refragmentada",
        hun: "Újratöredező valóság",
        swe: "Fragmenterar om verkligheten"
    },
    131: {
        img: "snowfalled",
        eng: "Snowfallen",
        schema: 143,
        pol: "spadający śnieg",
        fre: "Neige tombée",
        sch: "雪花飘落",
        bra: "Nevasca",
        hun: "Behavazott",
        swe: "Snögnistrande"
    },
    132: {
        img: "snowblinded",
        eng: "Snowblinded",
        schema: 144,
        pol: "oślepiający śnieg",
        fre: "Aveuglé par la neige",
        sch: "茫茫大雪",
        bra: "Cortina de neve",
        hun: "Hóvakított",
        swe: "Snöbländande"
    },
    133: {
        img: "pyroland_daydream",
        eng: "Pyroland Daydream",
        schema: 145,
        pol: "pyrolandowy sen na jawie",
        fre: "Rêve de Pyroland",
        sch: "幻境迷梦",
        bra: "Sonhos da Pyrolândia",
        hun: "Piróföldi álmodozás",
        swe: "Dagdröm i Pyroland"
    },
    134: {
        img: "verdatica",
        eng: "Verdatica",
        schema: 147,
        pol: "werdatyka",
        fre: "Verdatica",
        sch: "绿色流光",
        bra: "Verdático",
        hun: "Zöldellő",
        ger: "Verdatisch"
    },
    135: {
        img: "aromatica",
        eng: "Aromatica",
        schema: 148,
        pol: "aromatyka",
        fre: "Aromatica",
        sch: "橙色流光",
        bra: "Aromático",
        hun: "Aromás",
        ger: "Aromatisch"
    },
    136: {
        img: "chromatica",
        eng: "Chromatica",
        schema: 149,
        pol: "chromatyka",
        fre: "Chromatica",
        sch: "紫色流光",
        bra: "Cromático",
        hun: "Kromatikus",
        ger: "Chromatisch"
    },
    137: {
        img: "prismatica",
        eng: "Prismatica",
        schema: 150,
        pol: "pryzmatyka",
        fre: "Prismatica",
        sch: "蓝色流光",
        bra: "Prismático",
        hun: "Prizmatikus",
        ger: "Prismatisch"
    },
    138: {
        img: "bee_swarm",
        eng: "Bee Swarm",
        schema: 151,
        pol: "pszczeli rój",
        fre: "Essaim d'abeilles",
        sch: "愤怒蜂群",
        bra: "Enxame de abelhas",
        hun: "Méhraj",
        swe: "Bisvärm"
    },
    139: {
        img: "frisky_fireflies",
        eng: "Frisky Fireflies",
        schema: 152,
        pol: "świetliste świetliki",
        fre: "Lucioles lumineuses",
        sch: "欢跃萤火",
        bra: "Pirilampos pimpões",
        hun: "Játékos szentjánosbogarak",
        swe: "Eldiga eldflugor"
    },
    140: {
        img: "smoldering_spirits",
        eng: "Smoldering Spirits",
        schema: 153,
        pol: "zapalone zjawy",
        fre: "Esprits espiègles",
        sch: "阴郁之灵",
        bra: "Espíritos espirituosos",
        hun: "Parázsló lelkecskék",
        ger: "Glühende Geister"
    },
    141: {
        img: "wandering_wisps",
        eng: "Wandering Wisps",
        schema: 154,
        pol: "wędrujące ogniki",
        fre: "Fées fêtardes",
        sch: "漫游萤火",
        bra: "Fogo-fátuo",
        hun: "Libbenő lidércek",
        swe: "Irrande irrbloss"
    },
    142: {
        img: "kaleidoscope",
        eng: "Kaleidoscope",
        schema: 155,
        pol: "kalejdoskop",
        fre: "Kaléidoscope",
        sch: "万花筒",
        bra: "Caleidoscópio",
        hun: "Kaleidoszkóp",
        swe: "Kalejdoskop"
    },
    143: {
        img: "green_giggler",
        eng: "Green Giggler",
        schema: 156,
        pol: "zielony zgrywus",
        fre: "Vert de peur",
        sch: "憨笑绿瓜",
        bra: "Verde de alegria",
        hun: "Zölden kacagó",
        swe: "Grönt garv"
    },
    144: {
        img: "laugh_o_lantern",
        eng: "Laugh-O-Lantern",
        schema: 157,
        pol: "śmiechobuzie",
        fre: "Rire citrouille",
        sch: "狂笑之瓜",
        bra: "Abóboras animadas",
        hun: "Rötyögő tök",
        swe: "Brandgult skratt"
    },
    145: {
        img: "plum_prankster",
        eng: "Plum Prankster",
        schema: 158,
        pol: "śliwkowy śmieszek",
        fre: "Raillerie violente",
        sch: "嘲笑紫瓜",
        bra: "Roxo de tanto rir",
        hun: "Lila hahota",
        swe: "Lila flabb"
    },
    146: {
        img: "pyroland_nightmare",
        eng: "Pyroland Nightmare",
        schema: 159,
        pol: "koszmar z Pyrolandu",
        fre: "Cauchemar de Pyroland",
        sch: "幻境梦魇",
        bra: "Pesadelos da Pyrolândia",
        hun: "Piróföldi rémálom",
        swe: "Mardröm från Pyroland"
    },
    147: {
        img: "gravelly_ghoul",
        eng: "Gravelly Ghoul",
        schema: 160,
        pol: "żwirowy ghul",
        fre: "Goule pétrifiée",
        sch: "庄严石像鬼",
        bra: "Criatura de cascalho",
        hun: "Kőszellem"
    },
    148: {
        img: "vexed_volcanics",
        eng: "Vexed Volcanics",
        schema: 161,
        pol: "wkurzający wulkanit",
        fre: "Goule volcanique",
        sch: "熔岩石像鬼",
        bra: "Vulto vulcânico",
        hun: "Vulkanikus zargató"
    },
    149: {
        img: "gourdian_angel",
        eng: "Gourdian Angel",
        schema: 162,
        pol: "dynioł stróż",
        fre: "Citrouille flottante",
        sch: "守护之瓜",
        bra: "Abóbora angelical",
        hun: "Tökangyal",
        ger: "Kürbisengel"
    },
    150: {
        img: "pumpkin_party",
        eng: "Pumpkin Party",
        schema: 163,
        pol: "dyniowa domówka",
        fre: "Fête de la citrouille",
        sch: "南瓜开会",
        bra: "Festa das abóboras",
        hun: "Tök jó buli",
        ger: "Kürbisparty"
    },
    151: {
        img: "blighted_snowstorm",
        eng: "Blighted Snowstorm",
        schema: 170,
        pol: "zniweczona burza śnieżna",
        hun: "Elátkozott hóvihar",
        bra: "Nevasca púrpura",
        fre: "Glace funèbre",
        ger: "Tobender Schneesturm"
    },
    152: {
        img: "distant_dream",
        eng: "Distant Dream",
        schema: 168,
        pol: "odległy sen",
        bra: "Ilusão distante",
        hun: "Áhítatos ábránd",
        fre: "Rêve lointain",
        ger: "Ferner Traum"
    },
    153: {
        img: "divine_desire",
        eng: "Divine Desire",
        schema: 167,
        pol: "boskie pożądanie",
        bra: "Desejo divino",
        hun: "Áhítatos áhítozás",
        fre: "Désir divin",
        ger: "Göttliches Verlangen"
    },
    154: {
        img: "frozen_fractals",
        eng: "Frozen Fractals",
        schema: 164,
        pol: "zamrożone fraktale",
        bra: "Fractais congelados",
        hun: "Fagyott fraktálok",
        fre: "Flocons glacés",
        ger: "Eiskristalle"
    },
    155: {
        img: "genus_plasmos",
        eng: "Genus Plasmos",
        schema: 172,
        pol: "genus plasmos",
        fre: "Origine de la création"
    },
    156: {
        img: "lavender_landfall",
        eng: "Lavender Landfall",
        schema: 165,
        pol: "lawendowa lawina",
        bra: "Flocos de lavanda",
        hun: "Levendula lebegés",
        fre: "Poudreuse violette",
        ger: "Lavendelfarbener Flockentanz"
    },
    157: {
        img: "mirthful_mistletoe",
        eng: "Mirthful Mistletoe",
        schema: 175,
        pol: "radosna jemioła",
        hun: "Felvidító fagyöngy",
        bra: "Visco vistoso",
        fre: "Gui festif",
        ger: "Heiterer Mistelzweig"
    },
    158: {
        img: "pale_nimbus",
        eng: "Pale Nimbus",
        schema: 171,
        pol: "blada chmura",
        hun: "Halvány dicsfény",
        fre: "Nuage pâle",
        ger: "Graupelschauer"
    },
    159: {
        img: "serenus_lumen",
        eng: "Serenus Lumen",
        schema: 173,
        pol: "serenus lumen",
        bra: "Serenum lumen",
        fre: "Lumière sereine"
    },
    160: {
        img: "special_snowfall",
        eng: "Special Snowfall",
        schema: 166,
        pol: "specjalny śnieg",
        bra: "Flocos especiais",
        hun: "Szokatlan szállingózás",
        fre: "Neige d'exception",
        ger: "Ungewöhnlicher Schneefall"
    },
    161: {
        img: "ventum_maris",
        eng: "Ventum Maris",
        schema: 174,
        pol: "ventum maris",
        bra: "Ventus maris",
        fre: "Vents et marées"
    },
    162: {
        img: "violent_wintertide",
        eng: "Violent Wintertide",
        schema: 169,
        pol: "gwałtowna fala zimy",
        hun: "Heves hófergeteg",
        bra: "Vendaval violento",
        fre: "Blizzard",
        ger: "Stürmische Winterzeit"
    },
    163: {
        img: "resonation",
        eng: "Resonation",
        schema: 177,
        pol: "rezonowanie",
        bra: "Ressonância",
        hun: "Rezonancia"
    },
    164: {
        img: "aggradation",
        eng: "Aggradation",
        schema: 178,
        pol: "agradacja",
        bra: "Agradação",
        hun: "Ingerencia"
    },
    165: {
        img: "lucidation",
        eng: "Lucidation",
        schema: 179,
        pol: "naświetlenie",
        bra: "Elucidação",
        hun: "Luminencia"
    },
    166: {
        img: "stunning",
        eng: "Stunning",
        schema: 180,
        pol: "ogłuszenie",
        bra: "Estonteante",
        hun: "Kábulatos"
    },
    167: {
        img: "ardentum_saturnalis",
        eng: "Ardentum Saturnalis",
        schema: 181
    },
    168: {
        img: "fragrancium_elementalis",
        eng: "Fragrancium Elementalis",
        schema: 182
    },
    169: {
        img: "reverium_irregularis",
        eng: "Reverium Irregularis",
        schema: 183
    },
    170: {
        img: "perennial_petals",
        eng: "Perennial Petals",
        schema: 185,
        pol: "okwiaty",
        bra: "Pétalas perenes",
        hun: "Örökszép szirmok"
    },
    171: {
        img: "flavorsome_sunset",
        eng: "Flavorsome Sunset",
        schema: 186,
        pol: "bogaty zachód Słońca",
        bra: "Ocaso saboroso",
        hun: "Ízléses naplemente"
    },
    172: {
        img: "raspberry_bloom",
        eng: "Raspberry Bloom",
        schema: 187,
        pol: "malinowy rozkwit",
        bra: "Explosão de framboesa",
        hun: "Málnavirág"
    },
    173: {
        img: "iridescence",
        eng: "Iridescence",
        schema: 188,
        pol: "iryzacja",
        bra: "Iridescência",
        hun: "Színjátszás"
    },
    174: {
        img: "tempered_thorns",
        eng: "Tempered Thorns",
        schema: 189,
        pol: "zahartowane ciernie",
        hun: "Szolid szarvak",
        swe: "Härdade horn"
    },
    175: {
        img: "devilish_diablo",
        eng: "Devilish Diablo",
        schema: 190,
        pol: "diabelski pomiot",
        bra: "Diabo diabólico",
        hun: "Ördögi ördög",
        swe: "Diabolisk djävul"
    },
    176: {
        img: "severed_serration",
        eng: "Severed Serration",
        schema: 191,
        pol: "wyostrzone zmysły",
        hun: "Heves hegyesség",
        swe: "Tjusiga taggar"
    },
    177: {
        img: "shrieking_shades",
        eng: "Shrieking Shades",
        schema: 192,
        pol: "wrzeszczące cienie",
        hun: "Sikoltó árnyak",
        swe: "Gapande gastar"
    },
    178: {
        img: "restless_wraiths",
        eng: "Restless Wraiths",
        schema: 193,
        pol: "niespokojne upiory",
        hun: "Nyugtalan lidércek",
        swe: "Osaliga andar"
    },
    179: {
        img: "infernal_wraith",
        eng: "Infernal Wraith",
        schema: 195,
        pol: "piekielne upiory",
        hun: "Pokoli lidérc",
        swe: "Infernaliska vålnader"
    },
    180: {
        img: "phantom_crown",
        eng: "Phantom Crown",
        schema: 196,
        pol: "widmowa korona",
        hun: "Fantomkorona",
        swe: "Fantomkrona"
    },
    181: {
        img: "ancient_specter",
        eng: "Ancient Specter",
        schema: 197,
        pol: "starożytne widmo",
        hun: "Ősi jelenés",
        swe: "Forntida spöke"
    },
    182: {
        img: "viridescent_peeper",
        eng: "Viridescent Peeper",
        schema: 198,
        pol: "zielonkawy podglądacz",
        hun: "Zöld kémlelő",
        swe: "Kusliga korpgluggar"
    },
    183: {
        img: "eyes_of_molten",
        eng: "Eyes of Molten",
        schema: 199,
        pol: "oczy stopionego",
        hun: "Izzó szemek",
        swe: "Brännande blick"
    },
    184: {
        img: "ominous_stare",
        eng: "Ominous Stare",
        schema: 200,
        pol: "złowieszcze spojrzenie",
        hun: "Baljós tekintet",
        swe: "Olycksbådande blick"
    },
    185: {
        img: "pumpkin_moon",
        eng: "Pumpkin Moon",
        schema: 201,
        pol: "dyniowy Księżyc",
        hun: "Tökhold",
        swe: "Pumpamåne"
    },
    186: {
        img: "frantic_spooker",
        eng: "Frantic Spooker",
        schema: 202,
        pol: "szalony straszak",
        hun: "Kétségbeesett kísértet",
        swe: "Frenetiskt spöke"
    },
    187: {
        img: "frightened_poltergeist",
        eng: "Frightened Poltergeist",
        schema: 203,
        pol: "przestraszony duch",
        bra: "Poltergeist pávido",
        hun: "Rémült kísértet",
        swe: "Skrämd poltergeist"
    },
    188: {
        img: "energetic_haunter",
        eng: "Energetic Haunter",
        schema: 204,
        pol: "energetyczna zjawa",
        hun: "Energikus kísértő",
        swe: "Energisk gast"
    },
    189: {
        img: "smissmas_tree",
        eng: "Smissmas Tree",
        schema: 205
    },
    190: {
        img: "hospitable_festivity",
        eng: "Hospitable Festivity",
        schema: 206
    },
    191: {
        img: "condescending_embrace",
        eng: "Condescending Embrace",
        schema: 207
    },
    192: {
        img: "sparkling_spruce",
        eng: "Sparkling Spruce",
        schema: 209
    },
    193: {
        img: "glittering_juniper",
        eng: "Glittering Juniper",
        schema: 210
    },
    194: {
        img: "prismatic_pine",
        eng: "Prismatic Pine",
        schema: 211
    },
    195: {
        img: "smissmas_swirl",
        eng: "Smissmas Swirl",
        schema: 212
    },
    196: {
        img: "twisting_lights",
        eng: "Twisting Lights",
        schema: 213
    },
    197: {
        img: "stardust_pathway",
        eng: "Stardust Pathway",
        schema: 214
    },
    198: {
        img: "flurry_rush",
        eng: "Flurry Rush",
        schema: 215
    },
    199: {
        img: "spark_of_smissmas",
        eng: "Spark of Smissmas",
        schema: 216
    },
    200: {
        img: "polar_forecast",
        eng: "Polar Forecast",
        schema: 218
    },
    201: {
        img: "shining_stag",
        eng: "Shining Stag",
        schema: 219
    },
    202: {
        img: "holiday_horns",
        eng: "Holiday Horns",
        schema: 220
    },
    203: {
        img: "ardent_antlers",
        eng: "Ardent Antlers",
        schema: 221
    },
    204: {
        img: "festive_lights",
        eng: "Festive Lights",
        schema: 223
    }
}